.ant-drawer {
  width: 100% !important;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: none !important;
  transition: none !important;
}

.ant-drawer-top.ant-drawer-open {
  animation: none !important;
  transition: none !important;
}

.ant-drawer-bottom .ant-drawer-content {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-drawer-body {
  background-color: var(--color-floatingElement);
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-message-custom-content {
  align-items: center;
  display: flex;
}

.ant-notification-notice {
  width: auto;
}

.ant-notification-notice {
  background-color: var(--color-websiteBackground);
}

.ant-notification-notice-icon {
  /* Makes it so notif icon and title are center aligned */
  margin-top: 2px;
}

.ant-notification-notice-message {
  color: var(--color-primary);
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1) !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.ant-notification-notice-description {
  color: var(--color-secondary);
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1) !important;
  font-weight: 400;
  margin-top: 8px;
  max-width: 40ch;
}

.ant-notification-notice-description:empty {
  display: none;
}

.anticon-info-circle path {
  fill: var(--color-primary);
}

.ant-notification-notice {
  border-radius: 12px !important;
  padding: 20px 24px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-inner {
  background-color: var(--color-websiteBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  overflow: hidden;
}

.ant-popover-inner-content {
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
}

.ant-tooltip {
  max-width: none;
}

.ant-tooltip-inner {
  border-radius: 16px;
  padding: 12px 16px !important;
}
body {
  background-color: var(--color-websiteBackground);
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}

html {
  height: 100%;
}

#__next {
  height: 100%;
}

.buttonPlain {
  border-style: none;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.buttonPlain:hover {
  cursor: pointer;
}

/* See https://stackoverflow.com/questions/1833734/display-inline-block-extra-margin/8262649#8262649 */
.hideText {
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
}

/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorCardBackground {
  background-color: var(--color-cardBackground);
}

.backgroundColorDimmer {
  background-color: var(--color-dimmer);
}

.backgroundColorFloatingElement {
  background-color: var(--color-floatingElement);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorMenuPopup {
  background-color: var(--color-menuPopup);
}

.backgroundColorPrimary {
  background-color: var(--color-primary);
}

.backgroundColorRed {
  background-color: var(--color-red);
}

.backgroundColorRedHover {
  background-color: var(--color-redHover);
}

.backgroundColorSecondary {
  background-color: var(--color-secondary);
}

.backgroundColorShader {
  background-color: var(--color-shader);
}

.backgroundColorSkeletonBase {
  background-color: var(--color-skeletonBase);
}

.backgroundColorSkeletonHighlight {
  background-color: var(--color-skeletonHighlight);
}

.backgroundColorTertiary {
  background-color: var(--color-tertiary);
}

.backgroundColorWebsiteBackground {
  background-color: var(--color-websiteBackground);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}


/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorCardBackground {
  color: var(--color-cardBackground);
}

.colorDimmer {
  color: var(--color-dimmer);
}

.colorFloatingElement {
  color: var(--color-floatingElement);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorMenuPopup {
  color: var(--color-menuPopup);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorRed {
  color: var(--color-red);
}

.colorRedHover {
  color: var(--color-redHover);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorShader {
  color: var(--color-shader);
}

.colorSkeletonBase {
  color: var(--color-skeletonBase);
}

.colorSkeletonHighlight {
  color: var(--color-skeletonHighlight);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorWebsiteBackground {
  color: var(--color-websiteBackground);
}

.colorWhite {
  color: var(--color-white);
}


:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-cardBackground: #fff;
  --color-dimmer: rgba(0, 0, 0, 0.36);
  --color-floatingElement: #fff;
  --color-ghost: #c6c6c6;
  --color-menuPopup: #fff;
  --color-primary: #201f2a;
  --color-red: #fd4646;
  --color-redHover: #dd1111;
  --color-secondary: #9796a5;
  --color-shader: #f5f4fe;
  --color-skeletonBase: #ebebeb;
  --color-skeletonHighlight: #f5f5f5;
  --color-tertiary: #dedde5;
  --color-websiteBackground: #fff;
  --color-white: #fff;
}

:root {
  --color-backgroundOverlay-darkMode: rgba(0, 0, 0, 0.3);
  --color-cardBackground-darkMode: #fff;
  --color-dimmer-darkMode: rgba(0, 0, 0, 0.36);
  --color-floatingElement-darkMode: #fff;
  --color-ghost-darkMode: #c6c6c6;
  --color-menuPopup-darkMode: #fff;
  --color-primary-darkMode: #201f2a;
  --color-red-darkMode: #fd4646;
  --color-redHover-darkMode: #dd1111;
  --color-secondary-darkMode: #9796a5;
  --color-shader-darkMode: #f5f4fe;
  --color-skeletonBase-darkMode: #ebebeb;
  --color-skeletonHighlight-darkMode: #f5f5f5;
  --color-tertiary-darkMode: #dedde5;
  --color-websiteBackground-darkMode: #fff;
  --color-white-darkMode: #fff;
}

:root {
  --color-backgroundOverlay-lightMode: rgba(0, 0, 0, 0.3);
  --color-cardBackground-lightMode: #fff;
  --color-dimmer-lightMode: rgba(0, 0, 0, 0.36);
  --color-floatingElement-lightMode: #fff;
  --color-ghost-lightMode: #c6c6c6;
  --color-menuPopup-lightMode: #fff;
  --color-primary-lightMode: #201f2a;
  --color-red-lightMode: #fd4646;
  --color-redHover-lightMode: #dd1111;
  --color-secondary-lightMode: #9796a5;
  --color-shader-lightMode: #f5f4fe;
  --color-skeletonBase-lightMode: #ebebeb;
  --color-skeletonHighlight-lightMode: #f5f5f5;
  --color-tertiary-lightMode: #dedde5;
  --color-websiteBackground-lightMode: #fff;
  --color-white-lightMode: #fff;
}

/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genFonts.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.body1Font {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: var(--font-weight-body1);
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1MediumFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1SemiBoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1BoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body2Font {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: var(--font-weight-body2);
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2MediumFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2SemiBoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2BoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body3Font {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: var(--font-weight-body3);
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.body3MediumFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.body3SemiBoldFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.body3BoldFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.header1Font {
  font-family: var(--font-family-header1);
  font-size: var(--font-size-header1);
  font-weight: var(--font-weight-header1);
  letter-spacing: var(--letter-spacing-header1);
  line-height: var(--line-height-header1);
}

.header2Font {
  font-family: var(--font-family-header2);
  font-size: var(--font-size-header2);
  font-weight: var(--font-weight-header2);
  letter-spacing: var(--letter-spacing-header2);
  line-height: var(--line-height-header2);
}

.header3Font {
  font-family: var(--font-family-header3);
  font-size: var(--font-size-header3);
  font-weight: var(--font-weight-header3);
  letter-spacing: var(--letter-spacing-header3);
  line-height: var(--line-height-header3);
}

.navLinkFont {
  font-family: var(--font-family-navLink);
  font-size: var(--font-size-navLink);
  font-weight: var(--font-weight-navLink);
  letter-spacing: var(--letter-spacing-navLink);
  line-height: var(--line-height-navLink);
}

.subheaderFont {
  font-family: var(--font-family-subheader);
  font-size: var(--font-size-subheader);
  font-weight: var(--font-weight-subheader);
  letter-spacing: var(--letter-spacing-subheader);
  line-height: var(--line-height-subheader);
}

.tinyLabelFont {
  font-family: var(--font-family-tinyLabel);
  font-size: var(--font-size-tinyLabel);
  font-weight: var(--font-weight-tinyLabel);
  letter-spacing: var(--letter-spacing-tinyLabel);
  line-height: var(--line-height-tinyLabel);
}


/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genFonts.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.body1Size {
  height: var(--line-height-body1);
  width: var(--line-height-body1);
}

.body2Size {
  height: var(--line-height-body2);
  width: var(--line-height-body2);
}

.body3Size {
  height: var(--line-height-body3);
  width: var(--line-height-body3);
}

.header1Size {
  height: var(--line-height-header1);
  width: var(--line-height-header1);
}

.header2Size {
  height: var(--line-height-header2);
  width: var(--line-height-header2);
}

.header3Size {
  height: var(--line-height-header3);
  width: var(--line-height-header3);
}

.navLinkSize {
  height: var(--line-height-navLink);
  width: var(--line-height-navLink);
}

.subheaderSize {
  height: var(--line-height-subheader);
  width: var(--line-height-subheader);
}

.tinyLabelSize {
  height: var(--line-height-tinyLabel);
  width: var(--line-height-tinyLabel);
}


:root {
  /* body1 */
  --font-family-body1: var(--dm-sans), sans-serif;
  --font-size-body1: 19px;
  --font-weight-body1: 400;
  --line-height-body1: 24px;

  /* body2 */
  --font-family-body2: var(--dm-sans), sans-serif;
  --font-size-body2: 15px;
  --font-weight-body2: 400;
  --line-height-body2: 22px;

  /* body3 */
  --font-family-body3: var(--dm-sans), sans-serif;
  --font-size-body3: 13px;
  --font-weight-body3: 400;
  --line-height-body3: 20px;

  /* header1 */
  --font-family-header1: var(--dm-sans), serif;
  --font-size-header1: 56px;
  --font-weight-header1: 400;

  /* header2 */
  --font-family-header2: var(--dm-sans), sans-serif;
  --font-size-header2: 44px;
  --font-weight-header2: 700;
  --line-height-header2: 56px;

  /* header3 */
  --font-family-header3: var(--dm-sans), sans-serif;
  --font-size-header3: 32px;
  --font-weight-header3: 500;
  --line-height-header3: 40px;

  /* navLink */
  --font-family-navLink: var(--dm-sans), sans-serif;
  --font-size-navLink: 20px;
  --font-weight-navLink: 500;
  --line-height-navLink: 22px;

  /* subheader */
  --font-family-subheader: var(--dm-sans), sans-serif;
  --font-size-subheader: 20px;
  --font-weight-subheader: 400;
  --line-height-subheader: 28px;

  /* tinyLabel */
  --font-family-tinyLabel: var(--dm-sans), sans-serif;
  --font-size-tinyLabel: 14px;
  --font-weight-tinyLabel: 700;
  --letter-spacing-tinyLabel: 0.5px;
  --line-height-tinyLabel: 20px;
}

@media only screen and (max-width: 768px) {
  :root {
    /* body1 */
    --font-size-body1: 16px;
    --line-height-body1: 20px;

    /* body2 */
    --font-size-body2: 12px;
    --line-height-body2: 18px;

    /* body3 */
    --font-size-body3: 10px;
    --line-height-body3: 26px;

    /* header1 */
    --font-size-header1: 36px;

    /* header2 */
    --font-size-header2: 28px;
    --line-height-header2: 34px;

    /* header3 */
    --font-size-header3: 24px;
    --line-height-header3: 30px;

    /* navLink */
    --font-size-navLink: 17px;
    --line-height-navLink: 22px;

    /* subheader */
    --font-size-subheader: 16px;
    --line-height-subheader: 22px;

    /* tinyLabel */
    --font-size-tinyLabel: 12px;
    --line-height-tinyLabel: 20px;
  }
}

:root {
  --box-shadow-button: 0px 0px 20px rgba(0, 0, 0, 0.12);

  --drop-shadow-button: drop-shadow(0px 0px 24px rgba(78, 76, 107, 0.12));
}
:root {
  --z-index-bottomTabs: 2;
  --z-index-backgroundOverlay: 3;
  --z-index-modal: 3;
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_637332';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e646061e0525e1e5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_637332';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c1c321c249fa2d1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_637332';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5c002d52be7588a7-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_637332';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ea268e756d999837-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_637332';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1b2f56b7f1d86453-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_637332';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/206846510577c502-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_637332';src: local("Arial");ascent-override: 94.72%;descent-override: 29.60%;line-gap-override: 0.00%;size-adjust: 104.73%
}.__className_637332 {font-family: '__DM_Sans_637332', '__DM_Sans_Fallback_637332';font-style: normal
}

